export const DEPOSIT = {
  MIN_AMOUNT: 1,
  PROCESSING_HOURS: 6,
}

export const WITHDRAWAL = {
  MIN_AMOUNT: 1,
  PROCESSING_HOURS: 6,
}

export const SUPPORT_EMAIL = 'support@upwealth.io'

export const SUPER_ADMIN_MAIN_EMAILS = [
  'darren@upwealth.io',
  'darren@wealthsquare.io',
  'support@wealthsquare.io',
  'george+admin@startupcraft.io',
]

export const BREAKOUT_ACCESS_EMAILS = [
  'michael@breakoutsolutions.com',
  'info+test@startupcraft.io',
]

export const MIN_ASSET_VALUE = 10

export const DetailedRole = {
  FullAccess: 'fullAccess',
  BreakoutAccess: 'breakoutAccess',
  FundManager: 'fundManager',
}
