import { RootLayout } from 'Components/Layout'

import { USER_ROLE } from 'Constants/ids'
import {
  BREAKOUT_ACCESS_EMAILS,
  DetailedRole,
  SUPER_ADMIN_MAIN_EMAILS,
} from 'Constants/values'

import NotFound from 'Pages/NotFound'
import Public from 'Pages/Public'
import SignOut from 'Pages/SignOut'

import Utils from 'Utils'

import {
  ADMIN_BREAKOUT_ROUTES,
  ADMIN_FUND_MANAGERS_ROUTES,
  ADMIN_ROUTES,
} from './admin'
import { APP_ROUTES } from './app'
import { AUTH_ROUTES } from './auth'
import Director from './Director'
import { KYC_ROUTES } from './kyc'
import { OAUTH_ROUTES } from './oauth'
import { ONBOARDING_ROUTES } from './onboarding'
import * as ROUTES from './routes'

export default function router(user) {
  const allRoutes = [
    {
      component: Public.Root,
      path: ROUTES.ROOT,
      exact: true,
    },
    {
      component: Public.TermsOfService,
      path: ROUTES.TERMS_OF_SERVICE,
      exact: true,
    },
  ]

  if (user) {
    switch (user.role) {
      case USER_ROLE.ADMIN:
      case USER_ROLE.SUPER_ADMIN: {
        let role = SUPER_ADMIN_MAIN_EMAILS.includes(
          user?.emailCredentials[0]?.email,
        )
          ? DetailedRole.FullAccess
          : DetailedRole.FundManager

        if (BREAKOUT_ACCESS_EMAILS.includes(user?.emailCredentials[0]?.email))
          role = DetailedRole.BreakoutAccess

        switch (role) {
          case DetailedRole.FullAccess:
            allRoutes.push(ADMIN_ROUTES)
            break
          case DetailedRole.BreakoutAccess:
            allRoutes.push(ADMIN_BREAKOUT_ROUTES)
            break
          case DetailedRole.FundManager:
            allRoutes.push(ADMIN_FUND_MANAGERS_ROUTES)
          // eslint-disable-next-line no-fallthrough
          default:
            break
        }

        break
      }

      case USER_ROLE.USER:
        // -- KYC route
        allRoutes.push(KYC_ROUTES)
        // --

        if (Utils.Me.isOnboardingPassed(user)) {
          allRoutes.push(APP_ROUTES)
          allRoutes.push(OAUTH_ROUTES)
        } else {
          allRoutes.push(ONBOARDING_ROUTES)
        }

        break
      default:
        break
    }
  } else {
    allRoutes.push(AUTH_ROUTES)
  }

  allRoutes.push({
    component: SignOut,
    path: ROUTES.SIGN_OUT,
    exact: true,
  })

  allRoutes.push({
    component: NotFound,
  })

  return [
    {
      component: RootLayout,
      routes: allRoutes,
    },
  ]
}

export { Director }
