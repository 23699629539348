import { AdminLayout } from 'Components/Layout'

import Admin from 'Pages/Admin'

import * as ROUTES from './routes'

export const ADMIN_ROUTES = {
  component: AdminLayout,
  path: ROUTES.ADMIN_ROOT,
  routes: [
    {
      component: Admin.Root,
      path: ROUTES.ADMIN_ROOT,
      exact: true,
    },
    {
      component: Admin.Transactions,
      path: ROUTES.ADMIN_TRANSACTIONS,
      exact: true,
    },
    {
      component: Admin.Orders,
      path: ROUTES.ADMIN_ORDERS,
      exact: true,
    },
    {
      component: Admin.AssetStats,
      path: ROUTES.ADMIN_ASSET_STATS,
      exact: true,
    },
    {
      component: Admin.UserKYC,
      path: ROUTES.ADMIN_USER_KYC,
      exact: true,
    },
    {
      component: Admin.UserTransactions,
      path: ROUTES.ADMIN_USER_TRANSACTIONS,
      exact: true,
    },
    {
      component: Admin.UserPortfolioTemplateLogs,
      path: ROUTES.ADMIN_USER_PORTFOLIO_TEMPLATE_LOGS(),
      exact: true,
    },
    {
      component: Admin.PortfolioTemplates,
      path: ROUTES.ADMIN_PORTFOLIO_TEMPLATES,
      exact: true,
    },
    {
      component: Admin.PortfolioTemplateLogs,
      path: ROUTES.ADMIN_PORTFOLIO_TEMPLATE_LOGS(),
      exact: true,
    },
    {
      component: Admin.PortfolioTemplateGroups,
      path: ROUTES.ADMIN_PORTFOLIO_TEMPLATE_GROUPS,
      exact: true,
    },
    {
      component: Admin.Users,
      path: ROUTES.ADMIN_USERS,
      exact: true,
    },
    {
      component: Admin.Settings,
      path: ROUTES.ADMIN_SETTINGS,
      exact: true,
    },
    {
      component: Admin.CryptoTokens,
      path: ROUTES.ADMIN_CRYPTO_TOKENS,
      exact: true,
    },
    {
      component: Admin.UserPortfolios,
      path: ROUTES.ADMIN_USER_PORTFOLIOS,
      exact: true,
    },
  ],
}

export const ADMIN_BREAKOUT_ROUTES = {
  component: AdminLayout,
  path: ROUTES.ADMIN_ROOT,
  routes: [
    {
      component: Admin.Root,
      path: ROUTES.ADMIN_ROOT,
      exact: true,
    },
    {
      component: Admin.PortfolioTemplates,
      path: ROUTES.ADMIN_PORTFOLIO_TEMPLATES,
      exact: true,
    },
    {
      component: Admin.PortfolioTemplateGroups,
      path: ROUTES.ADMIN_PORTFOLIO_TEMPLATE_GROUPS,
      exact: true,
    },
    {
      component: Admin.UserPortfolios,
      path: ROUTES.ADMIN_USER_PORTFOLIOS,
      exact: true,
    },
    {
      component: Admin.AssetStats,
      path: ROUTES.ADMIN_ASSET_STATS,
      exact: true,
    },
    {
      component: Admin.CryptoTokens,
      path: ROUTES.ADMIN_CRYPTO_TOKENS,
      exact: true,
    },
  ],
}

export const ADMIN_FUND_MANAGERS_ROUTES = {
  component: AdminLayout,
  path: ROUTES.ADMIN_ROOT,
  routes: [
    {
      component: Admin.Root,
      path: ROUTES.ADMIN_ROOT,
      exact: true,
    },
    {
      component: Admin.PortfolioTemplates,
      path: ROUTES.ADMIN_PORTFOLIO_TEMPLATES,
      exact: true,
    },
    {
      component: Admin.PortfolioTemplateGroups,
      path: ROUTES.ADMIN_PORTFOLIO_TEMPLATE_GROUPS,
      exact: true,
    },
  ],
}
